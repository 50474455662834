import configurations from '@next-app/config/configurations';
import urlMapping from '@next-app/config/urlMapping';
import { isBrowser } from './common-utils';
import { storeAuthInfo } from './authenticationUser';
import { Token, TokenResponseError } from '@next-app/interface/Login';

type FetchParams = {
  endpoint: string;
  options?: any;
  params?: any;
  body?: any;
  headers?: any;
  isExternal?: boolean;
  cache?: string;
  tags?: string[];
};

/**
 * Fetches the access token asynchronously.
 *
 * @return {Promise<Token | void>} The fetched access token or void.
 */
const fetchAccessToken = async (): Promise<Token | undefined> => {
  if (!isBrowser()) {
    return;
  }

  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    return;
  }
  const refreshTokenRequest = {
    client_id: 'login-app',
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };

  const url = localStorage.getItem('authIntegrationInfoUrl');

  if (!url) {
    return;
  }

  const refreshTokenResponse: Token & TokenResponseError = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(refreshTokenRequest).toString(),
  }).then((response) => response.json());

  if (refreshTokenResponse?.error) {
    throw new Error(
      'Error occurred in fetching token: ' + refreshTokenResponse.error,
    );
  } else {
    return refreshTokenResponse;
  }
};

export const makeFetchCall = async (fetchParams: FetchParams) => {
  try {
    const { baseUrl } = configurations;
    const {
      endpoint,
      params = {},
      options = {},
      body = {},
      headers = {},
      isExternal = false,
      cache,
      tags,
    } = fetchParams;

    const isParams = Object.keys(params).length > 0;
    const isBody = Object.keys(body).length > 0;

    let requestURL = isExternal
      ? endpoint
      : endpoint.match(/http/)
        ? endpoint
        : `${baseUrl}${endpoint}`;
    if (isParams) {
      requestURL = decodeURIComponent(
        `${requestURL}?${new URLSearchParams(params)}`,
      );
    }

    if (isBrowser()) {
      const storedToken = localStorage.getItem('accessToken');
      const profileId = localStorage.getItem('profileId');
      if (storedToken) {
        headers['Authorization'] = `Bearer ${storedToken}`;
        headers['profileId'] = profileId;
      }
    }

    const combinedHeaders = {
      Accept: 'application/json',
      isReact: 'true',
      'Content-Type': headers['Content-Type'] || 'application/json',
      ...headers,
    };

    if (!isBrowser()) {
      combinedHeaders['BlazeMeterKey'] = 'f472f34c-d105-45c8-b3b0-801f016e657e';
    }

    const methodType = options?.method || 'GET';

    if (
      methodType === 'GET' &&
      requestURL.includes('/products') &&
      !requestURL.includes('gsa')
    ) {
      combinedHeaders['Cookie'] = '';
    }

    const response = await fetch(requestURL, {
      method: methodType,
      cache,
      headers: combinedHeaders,
      ...(tags && { next: { tags } }),
      ...(isBody && { body: JSON.stringify({ ...body }) }),
      ...options,
    });

    if (!response.ok) {
      const requestSize = new TextEncoder().encode(
        JSON.stringify({ combinedHeaders }),
      ).length;

      console.log(
        `Request status:  ${response.status}, at url ${requestURL}, and header size: ${requestSize} bytes, and headers: ${JSON.stringify(combinedHeaders, null, 2)}`,
      );

      if (response.status === 401) {
        const token: Token | undefined = await fetchAccessToken();

        // update new token values in local storage
        if (token) {
          storeAuthInfo(token);

          // resend the previous call
          makeFetchCall(fetchParams);
        }
      } else if (response.status === 409) {
        const sessionConfRes: any = await makeFetchCall({
          endpoint: urlMapping.sessionConfirmationNumberUrl,
        });

        return sessionConfRes;
      } else {
        return response.statusText;
      }
    }

    const data = await response.json();
    // Do not print api calls in the browser
    // if (!isBrowser()) {
    //   try {
    //     const logObj = {
    //       headers: combinedHeaders,
    //       requestURL,
    //       fetchParams,
    //       response: data,
    //     };
    //     console.log('API Log: ', JSON.stringify(logObj));
    //   } catch (e) {
    //     console.log('logging exception: ', e);
    //   }
    // }

    return data;
  } catch (error) {
    console.error('makeFetchCall :: Catch :: Failed to fetch data:', error);
    throw error;
  }
};
