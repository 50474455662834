import { createSlice } from '@reduxjs/toolkit';

export const MasterProduct = createSlice({
  name: 'MasterProduct',
  initialState: {},
  reducers: {
    setMasterProductId(state: any, action) {
      const productId = action.payload;
      state.id = productId ? productId : '';
    },
  },
});

export const { setMasterProductId } = MasterProduct.actions;

export default MasterProduct.reducer;
