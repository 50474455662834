import { updateDynSessConf } from '@next-app/lib/store';
import { makeFetchCall } from './fetch-data';
import urlMapping from '@next-app/config/urlMapping';
import { URLS } from '@next-app/constants/constants';

interface TrackConversionReq {
  event: string;
  pageName: string;
  skuId?: string;
  orderId?: string;
  trackConversionBaseUrl?: string;
  dynSessConfNumber: string;
  dispatch: Function;
  profileId: string;
  cartId: string;
  productId?: string;
}

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const isBrowser = () => {
  return 'undefined' !== typeof window;
};

export const getUnformattedNumber = (phoneNumber: string) =>
  phoneNumber.replace(/\D/g, '');

export const isValidUrl = (url: string) => {
  const matchPatterns = [
    /^\/?products\/.+/, // Matches any URL that starts with /products/ or products/ followed by additional characters (e.g., /products/item123 or products/item123)
    /\/N\//, // Matches any URL that contains /N/ anywhere in the path (e.g., /category/N/item123)
    /\/p\//, // Matches any URL that contains /N/ anywhere in the path (e.g., /category/N/item123)
    /\/P\//, // Matches any URL that contains /N/ anywhere in the path (e.g., /category/N/item123)
    /\/s\//, // Matches any URL that contains /N/ anywhere in the path (e.g., /category/N/item123)
    /\/S\//, // Matches any URL that contains /N/ anywhere in the path (e.g., /category/N/item123)
    /^\/sale\/.+/, // Matches any URL that starts with /sale/ and is followed by additional characters (e.g., /sale/item123)
    /^\/sale$/, // Matches exactly the URL /sale (without trailing slash)
    /^\/sale\/$/, // Matches exactly the URL /sale/ (with a trailing slash)
  ];

  const notMatchPatterns = [
    /^\/products\/$/,
    /^\/products\/shop-by-category\/$/,
    /^\/products\/shop-by-age\/$/,
    /^\/products\/classroom-furniture\/$/,
    /^\/products\/learn\/$/,
    /^\/products\/play\/$/,
    /^\/products\/teaching-resources\/$/,
    /^\/products\/featured\/$/,
    /^\/products\/are-you-ready$/,
    /^\/products\/flex-space-jr$/,
  ];

  const matches = matchPatterns.some((pattern) => pattern.test(url));

  const notMatches = notMatchPatterns.some((pattern) => pattern.test(url));

  return matches && !notMatches;
};

export const withoutSlash = (path: string) => {
  return path.replace(/^\/+|\/+$/g, '');
};

export const closeHamburgerMenu = () => {
  document.querySelector('.mp-pushed')?.classList.remove('mp-pushed');
};

/**
 * resetHamburgerMenu
 * Closes hamburger menu and waits for 500ms (for animation to complete)
 * before collapsing all open levels
 */
export const resetHamburgerMenu = () => {
  closeHamburgerMenu();
  setTimeout(() => {
    const allActiveNodes = Array.from(
      document.querySelectorAll('nav .active, nav .show'),
    );
    for (let index = 0; index < allActiveNodes.length; index++) {
      allActiveNodes[index]?.classList.remove('active', 'show');
    }
  }, 500);
};

export const resetDesktopMenu = () => {
  const currentlyActiveElems = Array.from(
    document.querySelectorAll('.navigation-list.active'),
  );
  for (let index = 0; index < currentlyActiveElems.length; index++) {
    currentlyActiveElems[index]?.classList.remove('active');
  }
};

export const addTrailingSlash = (link: string = '') => {
  let [path, search] = link.split('?');
  path = path.endsWith('/') ? path : `${path}/`;
  return search ? `${path}?${search}` : path;
};

export const addRouteIsLoadingClass = (routeUrl: string) => {
  if (isBrowser() && routeUrl !== location.pathname) {
    document.body.classList.add('route-is-loading');
  }
};

export const removeRouteIsLoadingClass = () => {
  if (isBrowser()) {
    document.body.classList.remove('route-is-loading');
  }
};

function convertTimeToMin(time: any) {
  let [hour, min] = time.split(':');
  let [t, ampm] = time.trim().split(' ');
  if (ampm.toLowerCase() === 'am' && parseInt(hour) === 12) {
    hour = 0;
  }

  return parseInt(hour) * 60 + parseInt(min);
}

export function getTimeDiffInHM(t2: string, t1: string) {
  let t1Mins = convertTimeToMin(t1);
  let t2Mins = convertTimeToMin(t2);
  let minDiff = t2Mins - t1Mins;
  return [minDiff / 60, minDiff % 60];
}

export function formatCutOffTime(time: string) {
  if (time.indexOf(':') === -1) {
    time = time.slice(0, 2) + ':' + time.slice(2);
  }
  const [hour, min] = time.split(':');
  const [t, ampm] = time.split(' ');
  let formattedTime = parseInt(hour);
  if (ampm && ampm.toLowerCase() == 'pm' && formattedTime != 12) {
    formattedTime += 12;
  }
  return `${formattedTime}:${min}`;
}

export function formatTzToTime(tz: string) {
  var d = new Date();
  return Intl.DateTimeFormat('en-US', {
    timeZone: tz,
    hour: '2-digit',
    minute: '2-digit',
  }).format(d);
}

// Replaces "{}" in a string with actual values
export const populateTemplateString = (
  templateString: string,
  data: Record<string, any>,
) => {
  return templateString.replace(/{([^}]+)}/g, function (x) {
    return data[x.replace(/[{}]/g, '')] || '';
  });
};

export const isNotNullOrUndefined = (value: any) => {
  return value !== null && value !== undefined;
};

export const isNullOrUndefined = (value: any) => {
  return value === null && value === undefined;
};

export const isNotNullOrUndefinedorEmpty = (value: any) => {
  return value !== null && value !== undefined && value?.length > 0;
};

export const getFormattedPrice = (
  amount: number | undefined,
  currency = '$',
) => {
  if (isNotNullOrUndefined(amount)) {
    const price = numberFormatter.format(amount as number);
    return `${currency}${price}`;
  } else {
    return '';
  }
};

export function isCurrentDateInRange(startDate: string, endDate: string) {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return currentDate >= start && currentDate <= end;
}
export const showDynamicModal = () => {
  const modalInstance = new window.bootstrap.Modal('#dynamicModalWrapper');
  modalInstance?.show();
};

export const hideDynamicModal = () => {
  const modalInstance = window.bootstrap.Modal.getInstance(
    '#dynamicModalWrapper',
  );
  modalInstance?.hide();
};

export const hideExtraBackdrops = () => {
  const backdrops = document.querySelectorAll(
    '.modal-backdrop.fade.show',
  ) as NodeList;

  // Hide all except the last one
  backdrops.forEach((backdrop: any, index) => {
    if (index < backdrops.length - 1) {
      backdrop.style.display = 'none'; // Hide all except the last
    }
  });
};

export const refetch = async ({
  response,
  request,
  dispatch,
}: {
  response: any;
  request: any;
  dispatch: Function;
}) => {
  if (response?.stringSessionConfirmationNumber) {
    const newDynSessConfNumber = response?.stringSessionConfirmationNumber;
    dispatch(updateDynSessConf(newDynSessConfNumber));
    request = {
      ...request,
      body: {
        ...request.body,
        _dynSessConf: newDynSessConfNumber,
      },
    };
    return await makeFetchCall(request);
  }
};

export const TrackConversion = async ({
  event,
  pageName,
  skuId = '',
  orderId = '',
  trackConversionBaseUrl,
  dynSessConfNumber,
  dispatch,
  profileId = '',
  cartId = '',
  productId = '',
}: TrackConversionReq) => {
  const requestIpRes = await fetch('/api/get-ip/');
  const reqIp = await requestIpRes.json();
  const requestIP = reqIp.ip;

  const body = {
    event,
    pageName,
    requestIP,
    userAgent: navigator.userAgent,
    _dynSessConf: dynSessConfNumber,
    ...(skuId ? { skuId } : {}),
    ...(orderId ? { orderId } : {}),
    ...(profileId ? { profileId } : {}),
    ...(cartId ? { cartId } : {}),
    ...(productId && { productId }),
  };

  if (trackConversionBaseUrl) {
    let response = await makeFetchCall({
      endpoint: `${trackConversionBaseUrl}${urlMapping?.trackConversionUri}`,
      options: {
        method: 'POST',
      },
      body,
    });
    if (response?.status !== 'ok') {
      response = await refetch({
        response: response,
        request: body,
        dispatch,
      });
    }
    return response;
  }
};

export const isPlpPage = (url: string) => {
  const contains = (url: string, array: string[]) =>
    array.some((subString) => url.includes(subString));

  return (
    contains(url, URLS.PRODUCT_PAGE_URLS) && !contains(url, URLS.PDP_PAGE_URLS)
  );
};

export const isHomePage = (url: string) => {
  return URLS.HOME_PAGE_URLS.includes(url);
};
