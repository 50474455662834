/* eslint-disable no-unused-vars */
export enum SKUOptionUnselectStatus {
  SOLD_OUT = 'SOLD_OUT',
  DISCONTINUED = 'DISCONTINUED',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  SOLD_OUT = 'SOLD_OUT',
  DISCONTINUED = 'DISCONTINUED',
  INACTIVE = 'INACTIVE',
  CLEARANCE = 'CLEARANCE',
}

export enum ProductUse {
  CLASSROOM = 'classroom',
  HOME = 'home',
}

export enum Channels {
  ONLINE = 'online',
  STORE = 'store',
}
export interface Restrictions {
  message: string;
}

export interface PDPGAEventData {
  id: string;
  category: string;
  subcategory: string;
  ageRange: string;
  gradeRange: string;
  productName: string;
  productUse: [ProductUse.CLASSROOM, ProductUse.HOME];
}

export interface PDPProductPresellInfo {
  closestDate: string;
  preSellProduct: string;
  skuPreSellDateMap: {}; //TBD
}

export interface Badge {
  alt: string;
  propertyValue: string;
  assetId: string;
}
export interface PDPAllSKUsInfo {
  [key: string]: {
    badges: [Badge] | null; // TBD
    pdpPromoMessages: []; // TBD
    isNew: boolean;
  };
}

export interface TimeInfo {
  time: number;
}

export interface PDPBackorderInfo {
  additionalMessage: null;
  backOrderInstoreAvailableMsg: null;
  backOrderInstorePromptMsg: null;
  backorderSelectStoreMsg: null;
  cartShippingRestrictionMessage: null;
  hazardousRestrictionMsg: null;
  message: null;
  shipOption: null;
  type: null;
  value: null;
  backOrderInstorePrompt: boolean;
  backorder: boolean;
}

export interface PDPPriceInfo {
  bopisSale?: boolean;
  doorBuster?: boolean;
  superSavings?: boolean;
  special?: boolean;
  onSale?: boolean;
  sale?: boolean;
  saleMinPrice?: number;
  addlSaving?: boolean;
  gsa?: boolean;
  hasGSAPrice?: boolean;
  showGSA?: boolean;
  hasSpecialPrice?: boolean;
  clearance?: boolean;
  salePrice?: number;
  bopisSalePrice?: number;
  doorBusterPrice?: number;
  addlSaveSalePrice?: number;
  specialPrice?: number;
  bopisListPrice?: number;
  listPrice?: number;
  doorBusterListPrice?: number;
  doorBusterSalePrice?: number;
  minPrice?: number;
  saleMaxPrice?: number;
  maxPrice?: number;
  isDiscontinuedOrInactive?: boolean;
  isGiftcard?: boolean;
  giftcardPriceRange?: string;
}
export interface PDPChildSKUInfo {
  smallImage: null; // TBD
  readingLevel: null; // TBD
  warranty: null; // TBD
  wholesalePrice: null; // TBD
  height: null; // TBD
  licence: null; // TBD
  shape: null; // TBD
  storeSku: null; // TBD
  persLead: string; // TBD
  skuInfos: {}; // TBD
  weight: null; // TBD
  restrictions: Restrictions;
  resultListPrice: string;
  pageNumber: string;
  hideDeliveryOptions: boolean;
  minGrade: string;
  shipAsDigital: boolean;
  channel: [Channels.ONLINE, Channels.STORE];
  onlineOnly: boolean;
  type: string;
  salePriceIfNotListPrice: number;
  llExclusive?: boolean;
  formattedRecomendedAge: string;
  additionalInfo: string;
  id: string;
  persEnabled: boolean;
  backOrderInstorePrompt: boolean;
  defaultListPrice: number;
  image: string;
  backorderDate: TimeInfo;
  creationDate: TimeInfo;
  startDate: TimeInfo;
  backorderInfo: PDPBackorderInfo;
  maxGrade: string;
  version: number;
  buyingOptions: null;
  size: null;
  auxiliaryMediaSceneIds: [];
  minAge: number;
  giftWrap: boolean;
  persLength: number;
  resultSalePrice: string;
  status: Status;
  template: null;
  skuIcons: [];
  color: null;
  nonreturnable: boolean;
  displayName: string;
  description: null;
  siteGroupIds: [];
  isFurniture: boolean;
  seoUrl: string;
  itemAcl: null;
  isDoorbusterSku: boolean;
  design: string;
  customBadge: null;
  onSale: boolean;
  siteIds: string[];
  priceInfo: PDPPriceInfo;
  productUse: [ProductUse.CLASSROOM, ProductUse.HOME];
  onlineSiteIds: {
    empty: boolean;
  };
  discountable: boolean;
  shipOption: null;
  largeImageSceneId: string;
  quantity: null;
  salePrice: null;
  warnings: [];
  length: null;
  skuType: string;
  persTrail: string;
  maxAge: number;
  cogs: null;
  noExport: null;
  repositoryId: string;
  width: null;
  storeOnly: boolean;
  listPrice: null;
}

export interface PDPMessage {
  message: string;
}
export interface PDPRewardsMessageMapContent {
  vip_gold: PDPMessage;
  message: string;
  modalTitle: string;
  vip: PDPMessage;
  modalText: string;
  [key: string]: PDPMessage | string;
}

export interface PDPChartModalIdContent {
  sizeChart: boolean;
  seatingChart: boolean;
  readingChart: boolean;
}
export interface PDPOnSaleInfo {
  message?: string;
  detailed?: string;
}

export interface Breadcrumb {
  name: string;
  url: string;
}

export interface PDPAdditionalProductInfo {
  isHazardousRestrictionsApply: boolean;
  shipRestrictionImg: string;
  videoBaseURL: string;
  isSameBackorderDateForAllSkus: boolean;
  isGWPProduct: boolean;
  saleIfNotListMinPrice: number;
  videoImageName: string;
  videoProtocol: 'https://';
  formattedRecomendedAge: string;
  skuOptionUnselectStatus: [
    SKUOptionUnselectStatus.SOLD_OUT,
    SKUOptionUnselectStatus.DISCONTINUED,
  ];
  isProductValidToDisplay: Boolean;
  isWarningsApply: Boolean;
  presellInfo: PDPProductPresellInfo;
  gaEventData: PDPGAEventData;
  allSkusInfo: PDPAllSKUsInfo;
  allChildSkus: [PDPChildSKUInfo];
  videoSceneSevenEnv: string;
  childSkus: [PDPChildSKUInfo];
  chartModalURLs?: {
    readingChart?: string;
  };
  defaultParentCategoryId: string;
  warrantyAssets: Record<string, string>;
  formattedRecommendedAgeIgnoreSkuStatus: string;
  rewardsMessageMap: PDPRewardsMessageMapContent;
  chartModalIDs: PDPChartModalIdContent;
  productStatus: boolean;
  isNew: boolean;
  onSaleInfo: PDPOnSaleInfo;
  special: boolean;
  sale: boolean;
  gsa: boolean;
  defaultBreadcrumbs: Breadcrumb[];
  minPrice: number;
  croppedDescription: string;
  isGreenguard: boolean;
  isFullyAssembled: boolean;
  productSeoUrl: string;
  isShippingRestrictionsApply: boolean;
  warrantyImgMap: Record<string, string>;
  isDescriptionCropped: boolean;
  customBadgeImgMap: {};
  skuSaveMsgs?: {
    [key: string]: string;
  };
  skuBopisSaveMsgs?: {
    [key: string]: string;
  };
  productPriceRange?: PDPPriceInfo;
  osetMsg?: string;
}

export interface PDPOptionTypeToLabel {
  licence: string;
  buyingOptions: string;
  size: string;
  color: string;
  shape: string;
  design: string;
  readingLevel: string;
  repositoryId: string;
}

export interface PDPOptionsRepositoryId {
  type: 'repositoryId';
  default: boolean;
  value: string;
  displayName: string | null;
}
export interface PDPAvailableOptionsRepositoryId {
  repositoryId: PDPOptionsRepositoryId[];
}

export interface PDPAvailableSkuSelectedOptions {
  repositoryId: PDPOptionsRepositoryId;
}

export interface PDPProductInfoMediaContent {
  altTextMap: Record<string, string>;
  mediaIds: string[];
  media: string[];
  noDisplayWarrantyMediaIds: string[];
  video: string[];
  largeImageAltText: string;
}

export interface PDPOptionTypeToPropertyContent {
  licence: string;
  buyingOptions: string;
  size: string;
  color: string;
  shape: string;
  design: string;
  readingLevel: string;
}

export interface PDPOptionTypeToDefaultContent {
  licence: string;
  buyingOptions: string;
  size: string;
  color: string;
  shape: string;
  design: string;
  readingLevel: string;
  repositoryId: string;
}

export interface PDPProductInfoProductContent {
  name: string;
  childSKUs: []; // no to be used in react
  childSKUS: PDPChildSKUInfo[];
  priceRange: PDPPriceInfo;
  longDescription: string;
  briefDescription: string;
  lowestSalePrice: number;
  displayName: string;
  rating: number;
  highestSalePrice: number;
  repositoryId: string;
  customRatingsAndReviewsEnabled: boolean;
  currencyCode: string;
}
export interface PDPAvailableSkuOptions {
  selectedOptions: PDPAvailableSkuSelectedOptions;
  availableOptions: PDPAvailableOptionsRepositoryId;
  priority: string[];
  formattedRecomendedAge: string;
  selectedSkuPrice?: number;
  selectedSku?: PDPChildSKUInfo | null;
  addtitionalInfo?: [];
  restrictionsAndWarnings?: [];
}

export interface ProductCatalogActorProductAccessoryInfo {
  productMedia: PDPProductInfoMediaContent;
  optionTypeToLabel: PDPOptionTypeToLabel;
  product: PDPProductInfoProductContent;
  defaultOptionType: string;
  additionalProductInfo: PDPAdditionalProductInfo;
  optionTypeToDefault: PDPOptionTypeToDefaultContent;
  optionTypeToProperty: PDPOptionTypeToPropertyContent;
  defaultOptionProperty: string;
  customRatingsAndReviewsEnabled: boolean;
  availableSkuOptions: PDPAvailableSkuOptions;
  priority: [string];
  isShippingRestrictionsApply: boolean;
}

export interface ProductCatalogActorProductAccessories {
  accessories: [ProductCatalogActorProductAccessoryInfo];
}

export interface PDPProductInfoContent {
  '@type': 'PageContent-ProductInfo';
  name: 'Page Content - Product Info';
  customRatingsAndReviewsEnabled: boolean;
  isReviewsEnabled: boolean;
  bopisTimerText: string;
  productMedia: PDPProductInfoMediaContent;
  optionTypeToProperty: PDPOptionTypeToPropertyContent;
  rating: number;
  reviewCount: number;
  defaultOptionProperty: string;
  UtilsActorGetMessages: {
    messageTextValue: Record<string, string>;
  };
  timerStartDate: string; // mm/dd/yyyy
  timerEndDate: string; //mm/dd/yyyy
  product: PDPProductInfoProductContent;
  isWriteAReviewEnabled: boolean;
  productId: string;
  optionTypeToDefault: PDPOptionTypeToDefaultContent;
  recommendedCount: number;
  displaySavingsMsg: boolean;
  availableSkuOptions: PDPAvailableSkuOptions;
  priority: string[];
  ProductCatalogActorProductAccessories: ProductCatalogActorProductAccessories;
  optionTypeToLabel: PDPOptionTypeToLabel;
  defaultOptionType: string;
  additionalProductInfo: PDPAdditionalProductInfo;
}
export interface warningBadgesInformation {
  additionalMessage: string;
  message: string;
}
export interface WarningBadgeProps {
  warningBadgesInformation: warningBadgesInformation[];
  isAccessories: boolean;
}
