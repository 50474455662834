import { PDPBreadCrumbsContent } from './PDPBreadCrumbs';
import { PDPCarouselsContent } from './PDPCarouselsContent';
import { PDPMetaTagValues } from './PDPMetaTagValues';
import { PDPProductInfoContent } from './PDPProductInfo';
import { PDPReviewListContent } from './PDPReview';

export enum DELIVERY_METHODS {
  // eslint-disable-next-line no-unused-vars
  SHIP_ITEM = 'shipItem',
  // eslint-disable-next-line no-unused-vars
  IN_STORE_PICKUP = 'store',
}

export interface PDPPageData {
  pageContent: PDPPageContent;
}

export interface PDPPageContent {
  '@type': 'PageSlot' | 'RedirectAwareContentInclude';
  contents: ContentsEntity[];
  name: string;
}
export interface ContentsEntity {
  '@type': 'Page-OneColumn';
  name: string;
  metaContent: [];
  url: string;
  metaTagValues: PDPMetaTagValues;
  mainContent: PDPMainContent[];
}

export interface PDPMainContent {
  '@type': 'PageContent-Slot';
  name: 'Page Content - Slot';
  contents: PDPMainContentContents[];
}
export interface PDPMainContentContents {
  '@type': 'PageContent-SectionHTMLTag';
  name: 'Page Content - Section HTML Tag';
  attributes: string; // Do not use in react
  tag: string;
  enabled: boolean;
  content: [
    PDPBreadCrumbsContent,
    PDPProductInfoContent,
    PDPLazyLoadContent,
    PDPReviewListContent,
  ];
}

export interface PDPLazyLoadContent {
  // not adding anything here as it is not supposed to be used in react
}
